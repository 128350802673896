import React from 'react'
import PropTypes from 'prop-types'
import {filter, findIndex} from 'lodash'
import { connect } from 'react-redux'
import { getFormValues, submit } from 'redux-form'
import objectAssign from 'object-assign'
import {Space, Empty, Alert} from "antd";
import { ControlOutlined, SortAscendingOutlined, TableOutlined } from '@ant-design/icons';
import { ScrollableListView, SettingsContainer } from "../providers/StyleProvider/styles";
import { Icon } from '@ant-design/compatible'
import { Icon as GodooIcon } from 'components'
import moment from 'moment'
import {parse} from 'query-string'
import Loading from '../components/Loading'

import {getEnumValues} from '../providers/ReduxProvider/actions/reportActions'
import ReportDataTable from '../components/ReportDataTable'
import ReportSearchFieldsForm from '../components/ReportSearchFieldsForm'

import {
  addAsterisks,
  capitalize,
  checkUserRights,
  showMessage,
  getFormEditedFields,
  convertArrayToObject
} from '../utils/appHelper'
import Heatmap from '../components/UI/Heatmap'
import SortForm from '../components/UI/TableFilters/SortForm'
import * as config from '../constants/globalConfiguration'
import { appDefaults, MAX_REPORT_EXPORT_SIZE } from '../constants/globalConfiguration'
import { listingLayouts } from '../constants'
import {api, getRequest} from '../providers/ApiProvider'
import { hashHistory } from '../providers/HistoryProvider'
import { Tooltip } from 'antd'
import { getColumnSort } from "../utils/tableUtils";
import { __ } from '../utils/translationUtils'
import {setLastRemoteObjSearchActionCreator} from "../providers/ReduxProvider/actions/userActions";
import {ERROR} from "components/dist/Utils/LoggerUtils";
import {Button} from "components";
import {BatModal, CheckboxList, Tooltip as UITooltip} from "../components/UI";
import {
  countFiltersDiff,
  getSortHeaderMessage,
  SearchAttributeInverseMapping
} from "../components/UI/TableFilters/utils";

const loadingBar = require('nprogress')

const defaultHeatMapCenter = [9.19, 45.4642]

function getDeadline({orderDeadline, processDeadline}) {
  if ((!orderDeadline || !orderDeadline.percentage) && (!processDeadline || !processDeadline.deadline)) return null
  if ((orderDeadline && orderDeadline.percentage) && (!processDeadline || !processDeadline.percentage)) return orderDeadline
  if ((processDeadline && processDeadline.percentage) && (!orderDeadline || !orderDeadline.percentage)) return processDeadline

  return (orderDeadline && orderDeadline.percentage) > processDeadline && processDeadline.deadline
    ? orderDeadline
    : processDeadline
}

function createFeature(feature) {
  const {properties} = feature
  const {processData, priority, orderType, deadline: orderDeadline} = properties || {}
  const {title, responsibleEntities, responsibleUsers, deadline: processDeadline} = processData || {}

  const {priorityValues} = global.constants
  const deadline = getDeadline({orderDeadline, processDeadline})

  return {
    ...feature,
    properties: {
      ...properties,
      title,
      deadline: deadline?.deadline && moment(deadline.deadline).format(appDefaults.dateTimeFormat),
      deadlinePercentage: deadline?.percentage ? (deadline.percentage / 100) : 0,
      deadlineColor: deadline?.color,
      priorityLabel: priority,
      priorityIconProps: priorityValues[priority],
      users: responsibleUsers || [],
      companies: responsibleEntities || [],
      color: orderType?.color
    }
  }
}

class ReportPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      initialRender: true,
      searchMasksOpen: false,
      sortMasksOpen: false,
      waitingRequiredFields: false,
      sortingOpen: false,
      reportId: this.props.match.params.id,
      orderGroupId: this.props.match.params.orderGroupId,
      reportSpec: null,
      reportData: null,
      reportDataSpecified: null,
      heatMapItems: {},
      heatMapCenter: defaultHeatMapCenter,
      users: null,
      viewMode: listingLayouts.TABLE,
      organisations: null,
      pagination: {
        pageNumber: 1,
        pageSize: 25,
        totalCount: 0,
        alert: []
      },
      selectedColumns: undefined,
      currentSort: [],
      tableColSort: null,
      isExport: false,
      submittedFormValues: null
    }
    this.searchSubmitRef = React.createRef();
    this.sortSubmitRef = React.createRef();
    this.reset = this.reset.bind(this)
    this.extractConfiguration = this.extractConfiguration.bind(this)
    this.extractColumns = this.extractColumns.bind(this)
    this.getOrgs = this.getOrgs.bind(this)
    this.getReportSpec = this.getReportSpec.bind(this)
    this.getReportData = this.getReportData.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onSortChange = this.onSortChange.bind(this)
    this.setPageSize = this.setPageSize.bind(this)
    this.toggleColumnSelection = this.toggleColumnSelection.bind(this)
    this.getUsers = this.getUsers.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.submitSearch = this.submitSearch.bind(this)
    this.submitSort = this.submitSort.bind(this)
    this.setCurrentSort = this.setCurrentSort.bind(this)
    this.setSortAttributeValue = this.setSortAttributeValue.bind(this)
    this.setShowAllOrders = this.setShowAllOrders.bind(this)
    this.refreshData = this.refreshData.bind(this)
    this.handleViewMode = this.handleViewMode.bind(this)
    this.handleClusterSelect = this.handleClusterSelect.bind(this)
  }

  componentDidMount() {
    this.reset()
    this.getUsers()
  }

  componentDidUpdate(prevProps) {
    if (this.state?.initialRender || (prevProps.match?.params?.id !== this.props.match?.params?.id && prevProps?.history?.action === 'PUSH')) {
      this.reset()
    }
  }

  handleViewMode(viewMode) {
    if (!Object.values(listingLayouts).includes(viewMode)) {
      console.error('View Mode can only be of type listing or heatMap')
      return
    }

    this.setState({viewMode})
  }

  getOrgs() {
    return api.get('entities/?pageSize=100&type=organisation')
      .then(
        (response) => {
          this.setState({organisations: response.data})
        },
        (error) => {
          console.log(error)
        }
      )
  }

  getUsers() {
    api.get('/users?pageSize=200')
      .then(
        (response) => {
          this.setState({users: response.data})
        },
        (error) => {
          console.log(error)
        }
      )
  }

  extractColumns(reportSpec) {
    const columns = []
    if (!reportSpec) {
      return columns
    }
    reportSpec.displayAttributes.forEach((a) => {
      const attributeConfig = this.extractConfiguration(a.attributeId)
      if (attributeConfig?.displayable) {
        columns.push(attributeConfig.propertyLabel)
      }
    })
    return columns
  }

  reset() {
    this.setState({
      initialRender: false,
      isLoading: false,
      searchMasksOpen: false,
      reportId: this.props.match.params.id,
      reportSpec: null,
      reportData: null,
      reportDataSpecified: null,
      pagination: {
        pageNumber: 1,
        pageSize: 25,
        totalCount: 0,
        alert: []
      },
      selectedColumns: [],
      currentSort: [],
      tableColSort: null
    }, () => {
      this.getOrgs().then(() => {
        this.getReportSpec()
      })
    })
  }

  refreshData() {
    this.getReportData()
  }

  setCurrentSort(value) {
    this.setState({
      sortMasksOpen: false,
      tableColSort: null,
      currentSort: value
    })
  }

  setShowAllOrders(value) {
    const spec = {...this.state.reportSpec}
    spec.onlySelfOrders = value
    this.setState({reportSpec: spec}, () => {
      this.handleSubmit()
    })
  }

  setSortAttributeValue(attribute, value) {
    const attributes = [].concat(this.state.reportSpec.sortAttributes)
    const idx = findIndex(attributes, (a) => a.attributeId === attribute.attributeId)
    attributes[idx].value = value

    this.setState({reportSpec: objectAssign(this.state.reportSpec, {sortAttributes: attributes})})
  }

  calculateAlerts(pageSize) {
    return (
      [...(pageSize >= 100 ? [__('deadlines_disabled_warnings', 'capitalise')] : [])]
    )
  }

  async handleSubmit(firstSearch) {
    const { pagination, reportSpec, isExport, selectedColumns, currentSort, tableColSort } = this.state

    if (!isExport) this.setState({ isLoading: true })

    const _values = this.props.formValues

    let values = { ..._values }
    const { useRequestedOrderGroupId, type, guiUser, dispatch, lastSearches } = this.props

    const apiurl = (type === 'report') ? '/configurations/reports' : '/configurations/views'

    const requestBody = objectAssign({}, {
      id: reportSpec.id,
      name: reportSpec.name,
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageNumber,
      onlySelfOrders: reportSpec.onlySelfOrders,
      displayAttributes: reportSpec.displayAttributes.filter(({ attributeId }) => selectedColumns.includes(attributeId) ),
      sortAttributes: tableColSort || (currentSort || reportSpec.sortAttributes)?.filter(({enabled}) => enabled == null || enabled),
      settings: reportSpec?.settings
    })

    let newCurrentSort = currentSort

    if (firstSearch && lastSearches[reportSpec.id]?.cacheId) {
      const lastCached = await getRequest(String(lastSearches[reportSpec.id].cacheId))

      if(lastCached?.payload) {
        try {
          const payloadObj = JSON.parse(lastCached.payload)
          const saMap = convertArrayToObject(payloadObj?.searchAttributes, 'attributeId')

          const searchAttributes = this.state.reportSpec.searchAttributes.map((sa) => {
            const savedSA = saMap[sa.attributeId]
            return savedSA ? SearchAttributeInverseMapping(savedSA) : sa
          })

          values = {}

          searchAttributes.forEach((f) => {
            const config = this.extractConfiguration(f.attributeId)
            if (f.multiSelect && typeof f.value !== 'undefined') {
              values[f.attributeId] = f.value.split(',')
            } else if (f.mechanism === 'RANGE' && config.type === 'DATE') {
              values[`${f.attributeId}From`] = f.from && moment(f.from)
              values[`${f.attributeId}To`] = f.to && moment(f.to)
            } else if (f.mechanism === 'RANGE' && config.type !== 'DATE') {
              values[`${f.attributeId}From`] = f.from
              values[`${f.attributeId}To`] = f.to
            } else {
              values[f.attributeId] = f.value
            }
          })

          newCurrentSort = this.state.reportSpec.sortAttributes.map((sortAttr) => {
            const sa = payloadObj?.sortAttributes?.find(({ attributeId }) => sortAttr?.attributeId === attributeId)

            return sa ? sortAttr : { ...sortAttr, enabled: false }
          })

          requestBody.sortAttributes =  payloadObj?.sortAttributes
          requestBody.displayAttributes = payloadObj.displayAttributes
          requestBody.onlySelfOrders = !!payloadObj?.onlySelfOrders
          requestBody.pageSize = payloadObj.pageSize
          requestBody.pageNumber = payloadObj.pageNumber


        } catch (e) {
          ERROR(e)
        }
      }
    }

    const _attrs = [].concat(this.state.reportSpec.searchAttributes)

    const attrs = []
    _attrs.forEach((_r) => {
      const r = {..._r}
      const attributeInfo = this.extractConfiguration(_r.attributeId)
      // RANGE
      if (r.mechanism === 'RANGE') {
        if (attributeInfo.type === 'DATE') {
          if (values[`${r.attributeId}From`]) {
            // Values From round down to the nearest minute
            const roundDown = values[`${r.attributeId}From`].startOf('minute')
            values[`${r.attributeId}From`] = roundDown || undefined
          }
          if (values[`${r.attributeId}To`]) {
            // Values To round up to the nearest minute
            const toMoment = values[`${r.attributeId}To`]
            const roundUp = toMoment.second() || toMoment.millisecond() ? toMoment.add(1, 'minute').startOf('minute') : toMoment.startOf('minute')
            values[`${r.attributeId}To`] = roundUp || undefined
          }
        }

        if (typeof values[`${r.attributeId}From`] !== 'undefined' || values[`${r.attributeId}From`] !== '') {
          r.from = values[`${r.attributeId}From`]
        } else {
          delete values[`${r.attributeId}From`]
        }

        if (typeof values[`${r.attributeId}To`] !== 'undefined' || values[`${r.attributeId}To`] !== '') {
          r.to = values[`${r.attributeId}To`]
        } else {
          delete values[`${r.attributeId}To`]
        }

        /*
         * Apply Felix's Algorithm on range fields
         * 1)If both *from* and *to* are filled --> normal *range* search.
         * 2) If only *from* is filled --> *specic_value* search. (the from can contain wildcard)
         * 3) if only *to* is filled the webui fills the from value with the same value contained in the *to* --> *range* search (edited)
         */

        // Applies only to RANGE.
        if (r.mechanism === 'RANGE' && (r.to || r.from)) {
          const {to, from} = r
          if (!(attributeInfo.type === 'DATE')) {
            if (from && !to) {
              r.mechanism = 'SPECIFIC_VALUE'
              r.value = from
              delete r.from
              delete r.to
              delete r.multiSelect
            } else if (!from && to) {
              r.from = to
            }
          } else if (from && !to) {
            r.from = from.utc().format()
            r.to = from.add(1, 'minute').utc().format()
          } else if (!from && to) {
            r.to = to.utc().format()
            r.from = to.subtract(1, 'minute').utc().format()
          } else {
            r.from = from.utc().format()
            r.to = to.utc().format()
          }
        }

        // SPECIFIC_VALUE
      } else if (r.mechanism === 'SPECIFIC_VALUE') {
        if (attributeInfo.type === 'DATE' && values[r.attributeId]) {
          values[r.attributeId] = values[r.attributeId].utc().format()
        }

        if (typeof values !== 'undefined' && typeof values[r.attributeId] !== 'undefined' && values[r.attributeId] !== '') {
          if (config.TYPES_NO_ASTERISKS_ALLOWED.includes(attributeInfo?.type?.toUpperCase())) {
            r.value = values[r.attributeId]
          } else if (!r.hidden) {
            r.value = r.exactMatch ? values[r.attributeId] : addAsterisks(values[r.attributeId].toString())
          }

          if (r.value === 'true') {
            r.value = true
          }
          if (r.value === 'false') {
            r.value = false
          }
        } else {
          delete r.value
        }
        // ENUMERATION
      } else if (r.mechanism === 'ENUMERATION') {
        const requiresobfuscation = (r.obfuscationMethod != null && r.obfuscationValue != null)
        const _value = [].concat(values[r.attributeId])
        if (typeof values[r.attributeId] !== 'undefined' && values[r.attributeId].length > 0) {
          // If obfuscationMethod == byTypeEqualsUnionChildren, then select children entities too
          if (requiresobfuscation) {
            switch (r.obfuscationMethod) {
              case 'byTypeEqualsUnionChildren':
                _value.forEach((parent) => {
                  const found = filter(r.values, (v) => v.parent === parent)
                  if (found) {
                    found.forEach((child) => {
                      _value.push(child.id)
                    })
                  }
                })
                break
            }
          }

          r.value = _value

          if (_value.constructor === Array) {
            r.value = _value.join(',')
          }
        } else {
          delete r.value
        }
      }

      if ((values[r.attributeId] == undefined || values[r.attributeId] === '')
        && (values[`${r.attributeId}From`] == undefined || values[`${r.attributeId}From`] === '')
        && (values[`${r.attributeId}To`] == undefined || values[`${r.attributeId}To`] === '')
      ) {
        return
      }
      attrs.push(r)

      // remove if empty value
      if (values[r.attributeId] != undefined && values[r.attributeId]?.length === 0) {
        //  if (typeof values[r.fieldId] !== 'undefined' && values[r.fieldId].length === 0) {
        //    const idx = findIndex(attrs, (f) => f.fieldId === r.fieldId);
        const idx = findIndex(attrs, (f) => f.attributeId === r.attributeId)
        if (idx > -1) {
          attrs.splice(idx, 1)
        }
      }
    })

    requestBody.searchAttributes = attrs

    let requestParams = '?'

    if (useRequestedOrderGroupId) {
      requestParams += `orderGroupIds=${this.state.orderGroupId}&`
    }

    requestParams += 'saveRequest=true'

    if (isExport) {
      loadingBar.start()
      if (this.state.pagination.totalCount > MAX_REPORT_EXPORT_SIZE) {
        showMessage('warning', `${__('Only exporting first')} ${MAX_REPORT_EXPORT_SIZE} ${__('results')}`)
      }
      requestBody.pageSize = MAX_REPORT_EXPORT_SIZE
      api.post(`${apiurl}/results/export${requestParams}`, requestBody, {responseType: 'arraybuffer'})
        .then((response) => {
          if (response.status === 200) {
            const a = document.createElement('a')
            document.body.appendChild(a)
            a.style = 'display: none'
            const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
            const url = window.URL.createObjectURL(blob)
            a.href = url
            const name = `${moment().format(config.appDefaults.dateFormatFiles)}_${guiUser.username}_${reportSpec.name || 'report'}`
            a.download = `${name.replace(/([^a-zA-Z0-9\-_]|\s)+/g, "")}.xlsx`

            a.click()
            window.URL.revokeObjectURL(url)

            this.setState({isLoading: false})
            loadingBar.done()
          }
        }).catch((ex) => {
        this.setState({isLoading: false})
        loadingBar.done()
      })
    } else {
      this.setState({
        isLoading: true,
        searchMasksOpen: false,
        sortMasksOpen: false,
      }, () => {

        api.post(`${apiurl}/results${requestParams}`, requestBody)
          .then((response) => {
            const pageSize = parseInt(response.headers['x-page-size'])

            this.setState({
              reportData: response.data,
              reportDataSpecified: response.data,
              heatMapItems: this.handleHeatMapItems(response.data),
              pagination: objectAssign(
                this.state.pagination, {
                  totalCount: parseInt(response.headers['x-total-count']),
                  pageSize: pageSize,
                  pageNumber: parseInt(response.headers['x-page']),
                  alert: this.calculateAlerts(pageSize)
                }
              )
            }, () => {
              dispatch(setLastRemoteObjSearchActionCreator({
                cacheId: response?.headers?.["x-saved-request-id"],
                id: reportSpec.id
              }))
              loadingBar.done()
            })
          })
          .finally(() => this.setState({isLoading: false}))
      })
    }

    // reset isExport
    this.searchSubmitRef.current.handleFormValuesInject(values)
    this.setState({
      isExport: false,
      submittedFormValues: { ...values },
      reportSpec: { ...this.state.reportSpec, onlySelfOrders: requestBody.onlySelfOrders },
      selectedColumns: requestBody.displayAttributes.map(({ attributeId }) => attributeId),
      ...(tableColSort ? { currentSort: newCurrentSort } : {})
    })
  }

  submitSearch() {
    this.setState({searchMasksOpen: false }, this.handleSubmit)
  }

  submitSort() {
    this.setState({
      sortMasksOpen: false
    }, this.handleSubmit)
  }

  getReportSpec() {
    const {location, type} = this.props

    let apiurl = (type === 'report') ? '/configurations/reports' : '/configurations/views'
    this.setState({
      reportId: this.props.match.params.id,
      reportSpec: null,
      selectedColumns: []
    }, () => {
      loadingBar.start()
      api.get(`${apiurl}/${this.state.reportId}`)
        .then((response) => {
          const reportData = response.data
          // Perform request to get available values
          const enumsToGetValues = []

          reportData.searchAttributes.forEach((attribute) => {
            if (attribute.mechanism === 'ENUMERATION') {
              const enumId = this.extractConfiguration(attribute.attributeId).enumerationId
              if (enumId) {
                enumsToGetValues.push(enumId)
              }
            }
          })

          if (enumsToGetValues.length > 0) {
            enumsToGetValues.forEach((enumId) => {
              this.props.dispatch(getEnumValues(enumId))
            })
          }

          const pageSize = reportData?.settings?.pageLayout?.pageSize || this.state.pagination.pageSize
          this.setState({
            reportSpec: reportData || null,
            pagination: {
              ...this.state.pagination,
              pageSize: pageSize,
              alert: this.calculateAlerts(pageSize)
            },
            viewMode: reportData.settings && reportData.settings.pageLayout && reportData.settings.pageLayout.listingLayout && reportData.settings.pageLayout.listingLayout.defaultSelected
          }, () => {
            loadingBar.done()
            this.setState({
              selectedColumns: this.extractColumns(reportData),
              currentSort: reportData.sortAttributes,
              availableColumns: this.extractColumns(reportData)
            }, () => {
              const blockSubmission = reportData.searchAttributes?.some((f) => {
                if (!f.required) return false
                if (f.mechanism === 'RANGE') return f.from == undefined || f.to == undefined
                return f.value == undefined && f.values == undefined
              })

              if (!blockSubmission) this.handleSubmit(true)
              else this.setState({ searchMasksOpen: true, waitingRequiredFields: true })
            })
          })
        })
        .catch((error) => {
          if (error.request && error.request.status === 404) {
            const queryString = parse(location.search)
            hashHistory.push(queryString.backUrl || config.ordersDefaultUrl)
          }
        })
    })
  }

  getReportData() {
    this.props.dispatch(submit('reportSearchFieldsForm'))
  }

  onPageChange(page) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        pageNumber: page
      }
    }, () => {
      this.handleSubmit()
    })
  }

  onSortChange(sortName, sortOrder) {
    const sort = getColumnSort(sortName, sortOrder, this.state.reportSpec.sortAttributes)?.[0]
    this.setState({
      tableColSort: sort ? [sort] : null,
      currentSort: this.state.reportSpec.sortAttributes
    }, () => {
      this.handleSubmit()
    })
  }

  setPageSize(val) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        totalCount: this.state.pagination?.totalCount ? this.state.pagination.totalCount : this.state.reportDataSpecified.length,
        pageSize: val,
        pageNumber: 1
      },
    }, () => { this.handleSubmit() })
  }

  extractConfiguration(id) {
    return  this.props.attributesMap[id]
  }

  toggleColumnSelection(columnName) {
    const selected = this.state.selectedColumns.concat([])
    const idx = selected.indexOf(columnName)
    if (idx > -1) {
      selected.splice(idx, 1)
    } else {
      selected.push(columnName)
    }

    this.setState({selectedColumns: selected})
  }

  render() {
    const {
      selectedColumns,
      currentSort,
      reportDataSpecified,
      reportSpec,
      isLoading,
      pagination,
      viewMode,
      waitingRequiredFields,
      submittedFormValues,
      searchMasksOpen,
      sortMasksOpen,
      users,
      heatMapCenter,
      heatMapZoom,
      heatMapItems,
    } = this.state

    const {
      enumValues,
      guiUser,
      type,
      formValues,
      attributesList,
      attributesMap
    } = this.props

    const userRights = guiUser && guiUser.rights || []
    const initialValues = {}
    reportSpec && reportSpec.searchAttributes.forEach((f) => {
      const config = this.extractConfiguration(f.attributeId)
      if (f.multiSelect && typeof f.value !== 'undefined') {
        initialValues[f.attributeId] = f.value.split(',')
      } else if (f.mechanism === 'RANGE' && config.type === 'DATE') {
        initialValues[`${f.attributeId}From`] = f.from && moment(f.from)
        initialValues[`${f.attributeId}To`] = f.to && moment(f.to)
      } else if (f.mechanism === 'RANGE' && config.type !== 'DATE') {
        initialValues[`${f.attributeId}From`] = f.from
        initialValues[`${f.attributeId}To`] = f.to
      } else {
        initialValues[f.attributeId] = f.value
      }
    })

    const { rightMappings } = global.constants
    if (!viewMode) {
      this.setState({viewMode: listingLayouts.TABLE})
    }

    return (
      <ScrollableListView id="main-content">
        <Loading loading={isLoading}/>
        <h1 className="sticky">
          <div className="container">
            {reportSpec ? capitalize(__(reportSpec.name)) : __('loading')}
          </div>
        </h1>
        <SettingsContainer>
          <div className="left">
            {reportSpec && viewMode === listingLayouts.TABLE &&
              <Space size="middle" style={{marginLeft: "15px"}}>
                <BatModal
                  forceRender={true}
                  forceOpen={searchMasksOpen}
                  setForceOpen={(state) => { this.setState({searchMasksOpen: state}) }}
                  disabled={!reportSpec.searchAttributes?.length}
                  buttonBadgeCount={countFiltersDiff(submittedFormValues || initialValues, initialValues)}
                  buttonProps={{icon: <ControlOutlined/>}}
                  title={__('Filters')}
                  onSubmitCallback={this.submitSearch}
                  onCancelCallback={() => {
                    const toReset = getFormEditedFields({
                      submittedFormValues: submittedFormValues,
                      initialValues: initialValues,
                      actualFormValues: formValues
                    })
                    if (this.searchSubmitRef?.current && toReset) this.searchSubmitRef.current.handleFormValuesInject(toReset)
                  }}
                  footer={
                    <>
                      <Button
                        title={__('Reset')}
                        type="text"
                        onClick={(e) => this.searchSubmitRef.current?.handleReset(e)}
                      />
                      <Button
                        type="filled"
                        title={__('Search')}
                        disabled={isLoading}
                        onClick={(e) => this.submitSearch()}
                      />
                    </>
                  }
                >
                  {reportSpec && viewMode === listingLayouts.TABLE &&
                    <ReportSearchFieldsForm
                      componentRef={this.searchSubmitRef}
                      isLoading={isLoading}
                      guiUser={this.props.guiUser}
                      reportSpec={reportSpec}
                      fields={reportSpec.searchAttributes}
                      onPageChange={this.onPageChange}
                      attributesConfiguration={attributesList}
                      sortAttributes={reportSpec.sortAttributes}
                      extractConfiguration={this.extractConfiguration}
                      initialValues={initialValues}
                      enumValues={enumValues}
                      onSubmit={this.handleSubmit}
                      setShowAllOrders={this.setShowAllOrders}
                    />
                  }
                </BatModal>
                <BatModal
                  title={__('Sorting')}
                  size='small'
                  setForceOpen={(state) => { this.setState({sortMasksOpen: state})}}
                  forceOpen={sortMasksOpen}
                  disabled={!reportSpec.sortAttributes?.length}
                  buttonBadgeCount={!!this.state.tableColSort || countFiltersDiff(currentSort, reportSpec?.sortAttributes)}
                  showBadgeCount={false}
                  buttonProps={{icon: <SortAscendingOutlined/>}}
                  onSubmitCallback={() => this.sortSubmitRef.current?.submit()}
                  footer={
                    <>
                      <Button
                        title={__('Reset')}
                        type="text"
                        onClick={(e) => this.sortSubmitRef.current?.reset(reportSpec.sortAttributes)}
                        disabled={isLoading}
                      />
                      <Button
                        form="sort-form-id"
                        title={__("Apply Sorting")}
                        type="filled"
                        className="right"
                        disabled={isLoading}
                      />
                    </>
                  }
                >
                  <SortForm
                    value={currentSort}
                    header={!!this.state.tableColSort && <Alert message={getSortHeaderMessage(this.state.tableColSort?.[0], (id) => attributesMap[id])} showIcon />}
                    onSubmit={(items) => {
                      this.setCurrentSort(items)
                      this.submitSort()
                    }}
                    componentRef={this.sortSubmitRef}
                    getAttribute={(id) => attributesMap[id]}
                  />
                </BatModal>
                <UITooltip
                  buttonBadgeCount={countFiltersDiff(selectedColumns, this.extractColumns(reportSpec))}
                  showBadgeCount={false}
                  buttonProps={{
                    icon: <TableOutlined/>,
                    title: __('Columns')
                  }}
                >
                  <CheckboxList
                    items={this.extractColumns(reportSpec).map((value) => ({ value, label: __(value) }))}
                    value={selectedColumns}
                    onChange={(value) => {
                      this.setState({selectedColumns: value}, () => this.handleSubmit())
                    }}
                  />
                </UITooltip>
                {
                  (
                    !!countFiltersDiff(submittedFormValues || initialValues, initialValues) ||
                    !!countFiltersDiff(currentSort, reportSpec?.sortAttributes) ||
                    !!(this.extractColumns(reportSpec).length - selectedColumns.length) ||
                    !!this.state.reportSpec.onlySelfOrders
                  ) &&
                  <Button
                    type="text"
                    title={__('clear-filters', "capitalize_sentence")}
                    onClick={() => {
                      this.searchSubmitRef.current.handleFormValuesInject(initialValues)
                      this.sortSubmitRef.current?.reset(reportSpec.sortAttributes)
                      this.setState({
                        isExport: false,
                        submittedFormValues: { ...initialValues },
                        pagination: {
                          ...this.state.pagination,
                          pageSize: this.state.reportSpec?.settings?.pageLayout?.pageSize,
                          alert: this.calculateAlerts(this.state.reportSpec?.settings?.pageLayout?.pageSize),
                          pageNumber: this.state.reportSpec.pageNumber
                        },
                        currentSort: this.state.reportSpec.sortAttributes,
                        selectedColumns: this.extractColumns(this.state.reportSpec),
                        reportSpec: {
                          ...this.state.reportSpec,
                          onlySelfOrders: false
                        }
                      }, () => this.handleSubmit())
                    }}
                  />
                }
              </Space>
            }
          </div>
          {
            reportSpec &&
            checkUserRights(userRights, rightMappings.CAN_SEE_ALL_ORDERS) &&
            (
              <div className="col-xs-12 col-sm-12 text-right clearfix">
                <div className="right">
                  {
                    reportSpec?.settings?.pageLayout?.listingLayout.available.length > 1 &&
                    (
                      <div className="col-sm-1 mr-5" style={{ minWidth: "100px" }}>
                        <div className="col-sm-12 view-mode-options">
                          {reportSpec.settings.pageLayout.listingLayout.available.includes(listingLayouts.TABLE)
                            && (
                              <Icon
                                type="unordered-list"
                                style={viewMode === listingLayouts.TABLE ? {color: '#1ea5c7'} : {}}
                                onClick={() => this.handleViewMode(listingLayouts.TABLE)}
                              />
                            )}
                          {reportSpec.settings.pageLayout.listingLayout.available.includes(listingLayouts.HEATMAP)
                            && (
                              <Icon
                                type="environment"
                                style={viewMode === listingLayouts.HEATMAP ? {color: '#1ea5c7'} : {}}
                                onClick={() => this.handleViewMode(listingLayouts.HEATMAP)}
                              />
                            )}
                        </div>
                      </div>
                    )}
                  {viewMode !== listingLayouts.HEATMAP && (
                    <Tooltip title={__('ShowOnlySelfOrders')}><div className="btn-no-padding btn-std" onClick={() => this.setShowAllOrders(!reportSpec.onlySelfOrders)}><Icon className="icon-std" type={reportSpec.onlySelfOrders ? 'check-square' : 'border'} theme="outlined" /></div></Tooltip>
                  )}
                  <Tooltip title={__('refresh data')}><div className="btn-no-padding btn-std" onClick={() => this.handleSubmit()}><Icon className="icon-std" type="redo" theme="outlined" /></div></Tooltip>
                  <Tooltip title={__('export')}><div className="btn-no-padding btn-std" onClick={() => this.setState({ isExport: true }, () => this.props.dispatch(submit('reportSearchFieldsForm')))}><Icon className="icon-std" type="download" theme="outlined" /></div></Tooltip>
                </div>
              </div>
            )
          }
        </SettingsContainer>

        <div className="container-fluid report-view-page">
          {!reportDataSpecified && !isLoading && viewMode === listingLayouts.TABLE && waitingRequiredFields
            &&
            <Empty
              style={{justifySelf: "center", height: "auto", overflow: "unset", margin: "auto"}}
              description={
                <span>{__('Please fill the required fields in') + ' '}
                  <a onClick={(e) => {
                    e.preventDefault();
                    this.setState({searchMasksOpen: true})
                  }}>
                    {__('search parameters')}
                  </a>
                </span>
              }
            />
          }
          {reportDataSpecified && viewMode === listingLayouts.TABLE
            && (
              <div className={"not-snapshots"}>
                <ReportDataTable
                  {...this.props}
                  location={this.props.location}
                  data={reportDataSpecified}
                  viewConfiguration={reportSpec}
                  users={users}
                  attributesConfiguration={attributesList}
                  reportSpec={reportSpec}
                  pagination={pagination}
                  guiUser={this.props.guiUser}
                  isLoading={isLoading}
                  onPageChange={this.onPageChange}
                  onSortChange={this.onSortChange}
                  selectedColumns={selectedColumns}
                  updateData={() => {}}
                  setPageSize={this.setPageSize}
                  refreshData={this.refreshData}
                  type={type}
                />
              </div>
            )}
          {
            viewMode === listingLayouts.HEATMAP && heatMapItems && heatMapItems.features
            && (
              <div className="row ">
                <div className="col-xs-12" style={{marginTop: '1rem'}}>
                  <Heatmap
                    items={heatMapItems && {
                      ...heatMapItems,
                      features: heatMapItems.features.map(createFeature)
                    }}
                    onClusterSelect={this.handleClusterSelect}
                    center={heatMapCenter}
                    zoom={heatMapZoom}
                    clusterDefaultColor="#ffffff"
                    shouldFitBounds={JSON.stringify(heatMapCenter) === JSON.stringify(defaultHeatMapCenter)}
                    clusterThresholdsPropertyName="deadlinePercentage"
                    clusterThresholds={[
                      {
                        color: '#64D868',
                        limit: 0.2
                      },
                      {
                        color: '#D1DB5B',
                        limit: 0.4
                      },
                      {
                        color: '#FDE52C',
                        limit: 0.6
                      },
                      {
                        color: '#FA9013',
                        limit: 0.8
                      },
                      {
                        color: '#FF0000',
                        limit: 1
                      }
                    ]}
                  />
                </div>
              </div>
            )
          }

        </div>
      </ScrollableListView>
    )
  }

  handleHeatMapItems(orderList) {
    const {users, organisations} = this.state
    // For now I have to hardcode it, I hope in the future this behaviour will be changed
    const responsibleUserId = '100_58be96d00e823f552aa1a070'
    const responsibleOrgId = '100_58be96d00e823f552aa1a071'
    if (!Array.isArray(orderList)) {
      return {}
    }
    const processList = []
    orderList.forEach((order) => {
      if (!Array.isArray(order.processOverviews)) {
        return null
      }

      order.processOverviews.forEach((process) => {
        if (!process || !process.location || !Array.isArray(process.location.coordinates)) {
          return null
        }
        const mapCoordinates = [...process.location.coordinates].reverse()
        const geometry = {...process.location, coordinates: [...mapCoordinates, 0.0]}

        const responsibleUsers = Array.isArray(order.displayData[responsibleUserId]) ? order.displayData[responsibleUserId].filter((user) => user.parentObjectId === process.id).map((e) => ({...e, ...(users.find((u) => u.id === e.id) || {})})) : []

        const responsibleEntities = Array.isArray(order.displayData[responsibleOrgId]) ? order.displayData[responsibleOrgId].filter((org) => org.parentObjectId === process.id).map((e) => ({...e, ...(organisations.find((o) => o.id === e.id) || {})})) : []

        processList.push({
          type: 'Feature',
          properties: {
            id: order.id,
            priority: order.orderOverview.priority,
            deadline: order.orderOverview.deadline,
            orderType: order.orderOverview.orderType,
            processData: {
              ...process,
              responsibleUsers,
              responsibleEntities
            }
          },
          geometry: {...geometry}
        })
      })
    })

    return {
      type: 'ProcessNearDeadline',
      features: processList
    }
  }

  handleClusterSelect = ({center, items, zoom}) => {
    const ordersInCluster = this.state.reportData.filter((d) => items.includes(d.id))
    this.setState({
      reportDataSpecified: ordersInCluster,
      viewMode: listingLayouts.TABLE,
      heatMapCenter: center,
      heatMapZoom: zoom
    })
  }
}

ReportPage.propTypes = {
  dispatch: PropTypes.func,
  getEnumValues: PropTypes.func,
  enumValues: PropTypes.object,
  formValues: PropTypes.object,
  match: PropTypes.object,
  guiUser: PropTypes.object,
  useRequestedOrderGroupId: PropTypes.bool,
  location: PropTypes.object,
  type: PropTypes.string
}

ReportPage.defaultProps = {useRequestedOrderGroupId: false}

const ReportPageConnected = connect(
  (state) => ({
    enumValues: state.enumValues,
    guiUser: state.user.data,
    reduxState: state,
    formValues: getFormValues('reportSearchFieldsForm')(state),
    attributesList: state.ui.attributesList,
    attributesMap: state.ui.attributes,
    lastSearches: state.ui.lastSearches
  }),
  (dispatch) => ({
    dispatch,
    getEnumValues,
    submit
  })
)(ReportPage)

export default ReportPageConnected
